<template>
  <div class="random-draw">
    <img class="image-wrap" v-if="activity_data.image" :src="activity_data.image" alt="">
    <div class="title-wrap">
      <div @click="clickBlack" class="title">{{prize_data.name}}</div>
    </div>
    <div class="boxs-wrap">
      <!-- <div class="list-wrap">
        <img class="img" :src="prize_data.image" alt="">
        <div class="text">{{prize_data.name}}</div>
      </div> -->
      <div class="random-container">
        <div class="btn">
          <div class="btn-item" @click="startDraw" v-if="drawing">开始</div>
          <div class="btn-item" @click="closeDraw" v-else>结束</div>
        </div>
        <div class="boox-wrap">
          <div class="show-draw" :class="drawNumber == 1 ?'isActive':''">
            <div class="show-draw-item" v-for="(item,index) in drawList" :key="index">
              <span class="name">{{ item.name }}</span>
              <span class="phone">{{ item.mobile }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import common from "@/utils/common.js"
export default {
  data() {
    return {
      fileTemp: null,
      fetchData: [],
      drawNumber: 1,
      drawList: [],
      allNumber: 0,
      drawing: true,
      showDrawInfo: false,
      activity_data: {},
      prize_data: {},
      currentIndex: 0,
      infoId: '',
      activity_prize_id: '',
    }
  },
  mounted(){
    let info = JSON.parse(localStorage.getItem('info'))
    this.infoId = JSON.parse(localStorage.getItem('infoId'))
    this.activity_data = info.activity_data;
    this.prize_data = info.activity_prize_data;
    this.fetchData = info.activity_list_data;
    this.drawNumber = this.prize_data.quota
    this.activity_prize_id = this.prize_data.id
  },
  methods: {
    clickBlack(){
      this.$router.go(-1)
    },
    // 点击切换奖品
    clickIndex(item,index){
      this.currentIndex = index;
      this.drawNumber = item.quota
      this.activity_prize_id = item.id
    },
    getRandomArrayElements(arr, count) {
      var shuffled = arr.slice(0),
        i = arr.length,
        min = i - count,
        temp,
        index
      while (i-- > min) {
        index = Math.floor((i + 1) * Math.random())
        temp = shuffled[index]
        shuffled[index] = shuffled[i]
        shuffled[i] = temp
      }
      return shuffled.slice(min)
    },
    // 开始
    startDraw() {
      if (!this.fetchData.length) {
        this.$message.warning({
          message: `请先上传参与抽奖人员信息！`
        })
        return
      }
      if (this.drawNumber > this.fetchData.length) {
        this.$message.warning({
          message: `抽奖人数(${this.drawNumber}人)大于剩余未中奖人数(${this.fetchData.length}人)，请重新输入！`
        })
        return
      }
      this.showDrawInfo = true;
      this.timeInterval = setInterval(() => {
        this.drawing = false;
        this.drawList = this.getRandomArrayElements(this.fetchData, this.drawNumber);
      }, 10)
    },
    // 结束
    closeDraw() {
      clearInterval(this.timeInterval)
      let list_ids = []
      this.drawList.forEach(item => {
        let index = this.fetchData.findIndex(test => test.mobile === item.mobile);
        this.fetchData.splice(index, 1)
        list_ids.push(item.id)
      })
      console.log(list_ids);
      this.drawing = true;
      let params = {
        activity_id: this.infoId,
        activity_list_ids: JSON.stringify(list_ids),
        activity_prize_id: this.activity_prize_id
      }
      common.connect("/rafflev1/activity/lotteryRecord",params,(res)=>{

      });
    },
    // 替换字符串
    plusXing(str, frontLen=3, endLen=4, cha="*") {
      var len = str.length - frontLen - endLen;
      var xing = '';
      for (var i = 0; i < len; i++) {
        xing += cha;
      }
      return str.substring(0, frontLen) + xing + str.substring(str.length - endLen);
    }
  },
  beforeDestroy() {
    clearInterval(this.timeInterval)
  }
}
</script>

<style lang="scss">
.random-draw {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  color: #fff;
  background-size: 100% 100%;
  position: relative;
  .image-wrap{
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    vertical-align: bottom;
  }
  .title-wrap{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 12%;
    left: 50%;
    transform: translate(-50%,0);
    .title{
      font-size: 36px;
      color: #fff;
      cursor: pointer;
    }
  }
  .boxs-wrap{
    display: flex;
    justify-content: center;
  }
  .list-wrap{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 600px;
    height: 100vh;
    // padding: 0 60px;
    margin-left: 120px;
    .img{
      width: 400px;
      height: 400px;
      margin-top: 10px;
      vertical-align: bottom;
    }
    .text{
      margin-top: 30px;
      font-size: 20px;
      text-align: center;
    }
  }
  .random-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // margin-right: 60px;
    .btn{
      position: fixed;
      bottom: 10%;
      right: 10%;
      .btn-item{
        width: 80px;
        height: 80px;
        border-radius: 100%;
        background: #fff;
        font-size: 22px;
        color: red;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
    .boox-wrap{
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .isActive{
      justify-content: center !important;
    }
    .show-draw {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 80%;
      max-height: 600px;
      // margin-right: 60px;
      overflow-y: auto;
      // margin-top: -30px;
      .show-draw-item {
        width: calc((100% - 30px)/2);
        height: 90px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 20px;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        font-size: 36px;
        // padding: 20px 0;
        cursor: pointer;
        .name{
          margin-right: 15px;
          padding-left: 40px;
        }
        .phone{
          padding-right: 40px;
        }
      }
    }
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: #c1c1c1;
    background-color: rgba(239, 243, 248, 0.9);   //滚动框颜色
  }
  ::-webkit-scrollbar-track   {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
  }
  ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(239, 243, 248, 0.3);   //滚动条颜色
      background-color: #c5c8cd;
  }
  ::-webkit-scrollbar-thumb:hover {
      border-radius: 5px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.1);
  } 
  .el-upload-list__item .el-icon-close {
    display: none;
  }
  .el-upload-list__item-name {
    color: #fff;
  }
  .el-upload__tip {
    color: #f1f1f1;
  }
}
</style>
